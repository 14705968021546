import React from "react"
import Layout from "./layout"
import SEO from "./seo"
import {
  styles,
  SubHeading,
  Padding,
  PinkDelimiter,
  Text,
} from "../common/styles"
import { graphql, StaticQuery } from "gatsby"
import _ from "lodash"
import styled from "@emotion/styled"
import parse from "html-react-parser"
import { media } from "../common/mediaQueries"
import Img from "gatsby-image/withIEPolyfill"

const Post = props => {
  return (
    <Layout>
      <StaticQuery
        query={graphql`
          {
            allWordpressPost {
              edges {
                node {
                  id
                  title
                  content
                  excerpt
                  featured_media {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1920, quality: 90) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          const currentPost = _.filter(
            data.allWordpressPost.edges,
            edge => edge.node.id === props.pageContext.id
          )[0].node
          return (
            <>
              <SEO
                title={currentPost.title}
                description={currentPost.excerpt}
              />
              {_.get(
                currentPost,
                "featured_media.localFile.childImageSharp.fluid"
              ) && (
                <ImageWrapper
                  children={
                    <Img
                      alt="Patricia On Ice - Foto"
                      fluid={_.get(
                        currentPost,
                        "featured_media.localFile.childImageSharp.fluid"
                      )}
                    />
                  }
                />
              )}
              <Wrapper>
                <Padding value="2vw" axis="y" />
                <SubHeading color={styles.colors.blue.dark}>
                  {currentPost.title}
                </SubHeading>
                <Padding value="1vw" axis="y" />
                <PinkDelimiter />
                <Padding value="2vw" axis="y" />
                <Text>{parse(currentPost.content)}</Text>
              </Wrapper>
            </>
          )
        }}
      />
    </Layout>
  )
}

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  width: 80vw;
  height: 100%;
  margin: 0 auto;
  .wp-block-embed-youtube {
    width: 80vw;
    * {
      width: 60vw;
      height: 33.75vw;
      margin: 0 auto;
    }
  }
  ${media.mobile} {
    .wp-block-embed-youtube {
      width: 80vw;
      * {
        width: 80vw;
        height: 45vw;
        margin: 0 auto;
      }
    }
  }
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  * {
    flex: 0 0 30vw;
    object-position: top !important;
  }
  ${media.mobile} {
    * {
      flex: 0 0 50vw;
    }
  }
`

export default Post
